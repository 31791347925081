import React from 'react'
import get from 'lodash/get'
import Head from '@app/head'
import Modules from '../components/sanity-module'
import pageHeaders from '@rdk/config/react-page-headers'

export default function GenericPage(props) {
	const page = get(props, `pageContext.page`, {})
	const slug = get(page, `slug.current`)
	const headerModules = get(page, `pageTemplate.headerModules`, [])
	const footerModules = get(page, `pageTemplate.footerModules`, [])

	return (
		<>
			{pageHeaders.map((Header, key) => {
				return (
					<Header key={key} page={page} />
				)
			})}
			<Head title={slug !== `/` && page.title} />
			<Modules
				pageTitle={page.title}
				modules={headerModules}
			/>
			<Modules
				pageTitle={page.title}
				modules={page.modules}
			/>
			<Modules
				pageTitle={page.title}
				modules={footerModules}
			/>
		</>
	)
}